//* &: /MP/salesfinder/common/monthDiscount.js
'use strict';

const aMONTH_DISCOUNT = [
    {
        type: 'quantity',
        quantity: 3,
        percent: 5,
    },

    {
        type: 'quantity',
        quantity: 6,
        percent: 10
    },

    {
        type: 'quantity',
        quantity: 12,
        percent: 15
    }
];

const aMONTH_DISCOUNT_PERIOD = [
    1, ... aMONTH_DISCOUNT.map(t => t.quantity)
    //1, 3, 6, 12
];

module.exports = {aMONTH_DISCOUNT, aMONTH_DISCOUNT_PERIOD}
